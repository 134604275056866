import { LZSPAComponent, LZSPARouter } from "@eventbuilder-utils/lzspa";
import { setupTinyMCEEditors } from "../utils/tinymce";
import { EBUploadManager, EBUploader } from "../utils/uploads";
import { showErrorToast } from "../utils/toast";

const KEYCODE_TAB = 9;
const KEYCODE_ESCAPE = 27;

export default class EBModalLayout extends LZSPAComponent {
    basePath = "/";

    constructor(params, opts = {}) {
        super(params);
        
        document.body.appendChild(this.element);

        this.element.className = "modal";
        if (opts.className) {
            this.element.classList.add(opts.className.split(" "));
        }

        const modalId = `modal${Date.now()}`;

        this.element.innerHTML = `<div class="backdrop"></div>
            <div class="dialog" role="dialog" aria-modal="true" aria-labelledby="${modalId}_label">
                <div class="banner"></div>
                <div class="header">
                    <h1 id="${modalId}_label" class="title">${opts.title || ""}</h1>
                    <div class="subtitle">${opts.subtitle || ""}</div>
                </div>
                <!--<div class="close"><button class="btn" role="button" title="close" aria-label="close"><svg class="toggle" data-src="/assets/images/x-lg.svg" aria-hidden="true"></svg></button></div>-->
                <div class="content">${opts.content || ""}</div>
                <div class="controls"></div>
            </div>`;
        
        //this.element.querySelector("& > div.dialog > div.header > h1").setAttribute("id", `${modalId}_label`);

        //this.element.querySelector("& > div.dialog > div.close > button").addEventListener("click", (e) => this.close(undefined));

        this.basePath = opts.basePath || new URL(window.location.href).pathname;

        for (let control of (opts?.controls || [])) {
            this.addControl(control.name, control);
        }
    }

    get lzFirstInvalidElement() { 
        return this.lzGetElements(`input[data-lzproperty][required]:invalid, select[data-lzproperty][required]:invalid, textarea[data-lzproperty][required]:invalid`).find(element => element.closest(`.hidden`) == undefined) || null;
    }

    lzSetFocusOnFirstInvalidElement() {
        const element = this.lzFirstInvalidElement;
        if (element.tagName == "TEXTAREA") {
            const tinyEditor = element.nextSibling?.querySelector(".tox-edit-area > iframe")?.contentWindow?.document?.body;
            if (tinyEditor) {
                tinyEditor.focus();
                tinyEditor.reportValidity();
            } else {
                element.focus();
                element.reportValidity();
            }
        } else {
            element.focus();
            element.reportValidity();
        }
    }

    async lzOnInit(params) {
        await super.lzOnInit(params);

        setupTinyMCEEditors(this.element, (callback, value, meta) => this.onTinyMCEFilePicker.call(this, callback, value, meta));
    }

    lzOnDataChange(change) { super.lzOnDataChange(change); }

    async dispose() {
        return super.dispose();
    }

    get banner() {
        return this.lzGetElement("& > div.dialog > div.banner > img")?.src;
    }
    
    set banner(value) { 
        const element = this.lzGetElement("& > div.dialog > div.banner");
        if (!!!value) {
            element.children.forEach(c => c.remove());
        } else {
            if (element.children.length != 0) {
                element.children[0].src = value;
            } else {
                const img = element.appendChild(document.createElement("img"));
                img.src = value;
                img.setAttribute("aria-hidden", "true");
            }
        }
    }
    
    get title() {
        return this.lzGetElement("& > div.dialog > div.header > h1.title").innerText;
    }
    
    set title(value) {
        this.lzGetElement("& > div.dialog > div.header > h1.title").innerText = value || "";
    }

    get subtitle() {
        return this.lzGetElement("& > div.dialog > div.header > div.subtitle").innerText;
    }
    
    set subtitle(value) {
        this.lzGetElement("& > div.dialog > div.header > div.subtitle").innerText = value || "";
    }

    get controls() {
        return [...this.lzGetElements("& > div.dialog > div.controls > button")];
    }

    control(name) {
        return this.lzGetElement(`& > div.dialog > div.controls > button[data-ebcontrol="${name}"]`);
    }

    addControl(name, opts = {}) {
        const button = this.lzGetElement("& > div.dialog > div.controls").appendChild(document.createElement("button"));
        button.setAttribute("role", "button");
        button.setAttribute("title", opts.label || name);
        button.setAttribute("aria-label", opts.ariaLabel || opts.label || name);
        button.setAttribute("data-ebcontrol", name);
        button.setAttribute("class", "btn");
        if (opts.className) button.classList.add(opts.className.split(" "));
        if (!!opts.disabled) button.disabled = true;
        if (!!opts.hidden) button.style.setProperty("display", "none");
        button.innerHTML = `<span aria-hidden="true">${opts.label || name}</span>`;
        return button;
    }

    get content() {
        return this.contentElement.innerHTML;
    }

    set content(value) {
        if (value instanceof HTMLElement) {
            this.contentElement.replaceWith(value);
            this.contentElement.classList.add("content");
        } else if (typeof value === "string") {
            this.contentElement.innerHTML = value;
        } else {
            throw new Error("Unknown content type");
        }
    }

    get contentElement() {
        return this.lzGetElement(`& > div.dialog > div.content`);
    }

    async onTinyMCEFilePicker(callback, value, meta) {
        try {
            const file = await EBUploader.openDialog();
            if (file == undefined) return;

            const result = await EBUploader.uploadFile(file, `${this.basePath}/uploads/public`, document.querySelector(`div.tox div.tox-dialog input[type="url"]`));
    
            callback(result);
        } catch (ex) {
            console.error(ex);
            showErrorToast(ex.message || ex);
        }

        /*EBUploadManager.open(`${this.basePath}/uploads/public/`).then(result => {
            if (result == undefined) return;
            callback(result);
        }).catch(ex => { console.error(ex); showErrorToast(ex.message || ex); });*/
    }
    
    open() {
        this.element.style.display = "block";

        const focusableElements = [...this.element.querySelectorAll(`a[href]:not([tabindex^="-"]), 
            area[href]:not([tabindex^="-"]), 
            input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]), 
            input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked, 
            select:not([disabled]):not([tabindex^="-"]), 
            textarea:not([disabled]):not([tabindex^="-"]), 
            button:not([disabled]):not([tabindex^="-"]), 
            iframe:not([tabindex^="-"]), 
            audio[controls]:not([tabindex^="-"]), 
            video[controls]:not([tabindex^="-"]), 
            [contenteditable]:not([tabindex^="-"]), 
            [tabindex]:not([tabindex^="-"]), 
            div.tox div.tox-edit-area iframe`)].filter(element => element.offsetWidth || element.offsetHeight || element.getClientRects().length);

        const firstFocusableEl = focusableElements[0];
        const lastFocusableEl = focusableElements[focusableElements.length - 1];

        this.element.addEventListener('keydown', (e) => {
            const isTabPressed = (e.key === "Tab" || e.keyCode === KEYCODE_TAB);
            const isEscPressed = (e.key === "Escape" || e.keyCode === KEYCODE_ESCAPE);
        
            if (isTabPressed) {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            }
            if (isEscPressed) {
                this.close(undefined);
            }
        });

        firstFocusableEl?.focus();

        return new Promise((resolve, reject) => {
            (async () => { await this.lzOnInit(); })();
            this.resolveFn = resolve;
            this.rejectFn = reject;
        });
    }

    close(retVal) {
        this.dispose().then(result => {
            if (!result) return;
            this.element.style.display = "none";
            this.resolveFn(retVal);
        }).catch(ex => console.error(ex));
    }
    
    show() {
        this.open();
    }
    
    hide() {
        this.element.style.display = "none";
    }
}