import EBModalLayout from "../../layouts/modal";

import { AndFilter, EqualityFilter, GreaterThanEqualsFilter, LessThanEqualsFilter, NotFilter, OrFilter, parseFilter, SubstringFilter } from "@eventbuilder-utils/filter";
import { showErrorToast } from "../../utils/toast";

import "./style.scss";

export default class FilterModal extends EBModalLayout {
    #fields = [];

    static open(filter, fields) {
        return new FilterModal({ filter: filter, fields: fields }).open()
    }

    constructor(opts) {
        super({}, {
            className: "modal--lg",
            title: "Filter",
            static: true,
        });

        this.addControl("Save", { className: "primary" }).addEventListener("click", (ev) => {
            this.close(this.buildFilter(this.contentElement.children[0]));
        })

        this.addControl("Close").addEventListener("click", (ev) => {
            this.close(undefined);
        })

        if (typeof opts.fields == "function")
            this.#fields = opts.fields();
        else
            this.#fields = opts.fields || [];

        try {
            let result = parseFilter(opts.filter || `(&(${this.#fields[0]?.name || ""}=))`);
            this.buildView(result, this.contentElement);
        } catch (ex) {
            console.error(ex);
            showErrorToast("Unknown error parsing filter");
        }
    }

    /*show() {
        return new Promise((resolve, reject) => {
            this.on("eb:modal.close.click", (event) => { resolve(undefined); this.close(); });
            this.on("eb:modal.control.save.click", (event) => { resolve(this.buildFilter(this.content.children[0])); this.close(); });
            super.show();
        });
    }*/  

    handleFilterFieldChange(event) {
        let field = this.#fields.find(a => a.name == event.target.value);
        if ((field?.options?.length || 0) == 0) {
            event.target.parentElement.children[2].replaceWith(document.createElement("input"));
        } else {
            let valueDropdownElement = document.createElement("select");
            valueDropdownElement.insertAdjacentHTML("afterbegin", `<option value="">(Blank)</option>`);
            for (let option of field.options) {
                let valueOptionElement = valueDropdownElement.appendChild(document.createElement("option"));
                valueOptionElement.value = option.value;
                valueOptionElement.innerText = option.displayText || option.name || option.value;
            }
            event.target.parentElement.children[2].replaceWith(valueDropdownElement);
        }
    }

    buildView(filter, target = this.contentElement) {
        if (filter instanceof AndFilter || filter instanceof OrFilter) {
            let baseEl = target.appendChild(document.createElement("eb-filter"));
            let headEl = baseEl.appendChild(document.createElement("eb-filter-head"));
            let typeEl = headEl.appendChild(document.createElement("select"));
            let typeAndOptionEl = typeEl.appendChild(document.createElement("option"));
            typeAndOptionEl.value = "&";
            typeAndOptionEl.innerText = "Match all conditions";
            if (filter instanceof AndFilter)
                typeAndOptionEl.selected = true;
            let typeOrOptionEl = typeEl.appendChild(document.createElement("option"));
            typeOrOptionEl.value = "|";
            typeOrOptionEl.innerText = "Match any condition";
            if (filter instanceof OrFilter)
            typeOrOptionEl.selected = true;
        
            let removeButtonEl = headEl.appendChild(document.createElement("button"));
            removeButtonEl.innerHTML = `<svg data-src="/assets/images/trash.svg"></svg>`;
            removeButtonEl.ariaLabel = "delete group";
            removeButtonEl.addEventListener("click", event => {
                event.target.parentElement.parentElement.remove();
            });

            let listEl = baseEl.appendChild(document.createElement("eb-filter-list"));
            for (let subfilter of filter.filters)
                this.buildView(subfilter, listEl);
            
            let footEl = baseEl.appendChild(document.createElement("eb-filter-foot"));
            let addSubButtonEl = footEl.appendChild(document.createElement("button"));
            addSubButtonEl.innerHTML = `<svg data-src="/assets/images/folder-plus.svg"></svg>`;
            addSubButtonEl.ariaLabel = "insert sub group";
            addSubButtonEl.addEventListener("click", (event) => {
                this.buildView(new AndFilter([new EqualityFilter(this.#fields[0].name,"")]), event.target.parentElement.parentElement.children[1]);
            });
            let addButtonEl = footEl.appendChild(document.createElement("button"));
            addButtonEl.innerHTML = `<svg data-src="/assets/images/plus.svg"></svg>`;
            addButtonEl.ariaLabel = "insert property";
            addButtonEl.addEventListener("click", (event) => {
                this.buildView(new EqualityFilter(this.#fields[0].name,""), event.target.parentElement.parentElement.children[1]);
            });
        } else {
            let baseEl = target.appendChild(document.createElement("eb-filter-list-item"));

            let fieldSelectElement = baseEl.appendChild(document.createElement("select"));
            for (let field of this.#fields) {
                let fieldOptionElement = fieldSelectElement.appendChild(document.createElement("option"));
                fieldOptionElement.value = field.name;
                fieldOptionElement.innerText = field.displayText || field.name;
                if (filter instanceof NotFilter) {
                    if (filter.filters[0].attribute == field.name) {
                        fieldOptionElement.selected = true;
                    }
                } else {
                    if (filter.attribute == field.name) {
                        fieldOptionElement.selected = true;
                    }
                }
                fieldSelectElement.addEventListener("change", event => this.handleFilterFieldChange(event));
            }

            let operatorElement = baseEl.appendChild(document.createElement("select"));
            let operatorEqualsOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorEqualsOptionElement.value = "=";
            operatorEqualsOptionElement.innerText = "Equals";
            if (filter instanceof EqualityFilter)
                operatorEqualsOptionElement.selected = true;

            let operatorNotEqualsOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorNotEqualsOptionElement.value = "!=";
            operatorNotEqualsOptionElement.innerText = "Does not equal";
            if (filter instanceof NotFilter && filter.filters[0] instanceof EqualityFilter)
                operatorNotEqualsOptionElement.selected = true;

            let operatorGreaterThanOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorGreaterThanOptionElement.value = ">=";
            operatorGreaterThanOptionElement.innerText = "Greater than";
            if (filter instanceof GreaterThanEqualsFilter)
                operatorGreaterThanOptionElement.selected = true;

            let operatorNotGreaterThanOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorNotGreaterThanOptionElement.value = "!>=";
            operatorNotGreaterThanOptionElement.innerText = "Not greater than";
            if (filter instanceof NotFilter && filter.filters[0] instanceof GreaterThanEqualsFilter)
                operatorNotGreaterThanOptionElement.selected = true;

            let operatorLessThanOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorLessThanOptionElement.value = "<=";
            operatorLessThanOptionElement.innerText = "Less than";
            if (filter instanceof LessThanEqualsFilter)
                operatorLessThanOptionElement.selected = true;

            let operatorNotLessThanOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorNotLessThanOptionElement.value = "!<=";
            operatorNotLessThanOptionElement.innerText = "Not less than";
            if (filter instanceof NotFilter && filter.filters[0] instanceof LessThanEqualsFilter)
                operatorNotLessThanOptionElement.selected = true;

            let operatorContainsOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorContainsOptionElement.value = "=*";
            operatorContainsOptionElement.innerText = "Contains";
            if (filter instanceof SubstringFilter) {
                operatorContainsOptionElement.selected = true;
                filter.value = filter.value.replace(/^\*/,"").replace(/\*$/,"");
            }

            let operatorNotContainsOptionElement = operatorElement.appendChild(document.createElement("option"));
            operatorNotContainsOptionElement.value = "!=*";
            operatorNotContainsOptionElement.innerText = "Does not contain";
            if (filter instanceof NotFilter && filter.filters[0] instanceof SubstringFilter) {
                operatorNotContainsOptionElement.selected = true;
                filter.filters[0].value = filter.filters[0].value.replace(/^\*/,"").replace(/\*$/,"");
            }
            
            let field = this.#fields.find(a => a.name == filter.attribute);
            if ((field?.options?.length || 0) > 0) {
                let valueDropdownElement = baseEl.appendChild(document.createElement("select"));
                for (let option of [ { displayText: "(Blank)", value: "" }, ...field.options ]) {
                    let valueOptionElement = valueDropdownElement.appendChild(document.createElement("option"));
                    valueOptionElement.value = option.value;
                    valueOptionElement.innerText = option.displayText || option.name || option.value;
                    if (filter instanceof NotFilter) {
                        if (filter.filters[0].value == option.value) {
                            valueOptionElement.selected = true;
                        }
                    } else {
                        if (filter.value == option.value) {
                            valueOptionElement.selected = true;
                        }
                    }
                }
            } else {
                let valueElement = baseEl.appendChild(document.createElement("input"));
                if (filter instanceof NotFilter)
                    valueElement.value = filter.filters[0].value || "";
                else
                    valueElement.value = filter.value || "";
            }

            let removeFilterButtonElement = baseEl.appendChild(document.createElement("button"));
            removeFilterButtonElement.innerHTML = `<svg data-src="/assets/images/trash.svg"></svg>`;
            removeFilterButtonElement.addEventListener("click", event => {
                if (event.target.closest("eb-filter-list").children.length == 1)
                    event.target.closest("eb-filter").remove();
                else
                    event.target.closest("eb-filter-list-item").remove();
            });
        }
    }

    buildFilter(target) {
        if (target?.tagName == "EB-FILTER") {
            if (target.children[1].children.length == 0)
                throw new Error("Invalid schema");
            return `(${target.children[0].children[0].value}${[...target.children[1].children].map(child => this.buildFilter(child)).join("")})`;
        } else if (target?.tagName == "EB-FILTER-LIST-ITEM") {
            let retVal;
            if (target.children[1].value == "=" || target.children[1].value == "!=")
                retVal = new EqualityFilter(target.children[0].value, target.children[2].value);
            else if (target.children[1].value == ">=" || target.children[1].value == "!>=")
                retVal = new GreaterThanEqualsFilter(target.children[0].value, target.children[2].value);
            else if (target.children[1].value == "<=" || target.children[1].value == "!<=")
                retVal = new LessThanEqualsFilter(target.children[0].value, target.children[2].value);
            else if (target.children[1].value == "=*" || target.children[1].value == "!=*")
                retVal = new SubstringFilter(target.children[0].value, `*${target.children[2].value.replace(/^\*/,"").replace(/\*$/,"")}*`);
            else
                throw new Error("Unknown filter");
            if (target.children[1].value.substring(0,1) == "!")
                retVal = new NotFilter(retVal);
            return retVal.toString();
        } else {
            return "";
            for (let child of target.children)
                return this.buildFilter(child);
        }
    }
}