import { formatTimestamp, getTimezones, guessTimezone, parseDateTime } from "@eventbuilder-utils/datetime";

class EBDateTimePicker extends HTMLInputElement {
    constructor() {
        super();

        this.style.setProperty("display", "flex");

        this.setAttribute("data-lzproperty", this.getAttribute("data-ebtimestampproperty"));

        this.innerHTML = `<input type="hidden" data-lzproperty="${this.getAttribute("data-ebtimestampproperty")}"><input type="date" min="${this.getAttribute("data-ebdatemin")}" max="${this.getAttribute("data-ebdatemax")}"><input type="time"><select style="flex:1 1 auto;" data-lzproperty="${this.getAttribute("data-ebtimezoneproperty")}">${getTimezones().map(tz => `<option value="${tz.ianaZone}">${tz.displayName}</option>`).join("")}</select>`;

        this.children[1].addEventListener("change", (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            try {
                this.children[0].value = parseDateTime(`${this.children[1].value}T${this.children[2].value}`, "YYYY-MM-DDTHH:mm", this.children[3].value);
                this.children[0].dispatchEvent(new Event("change"));
            } catch (ex) { console.warn(ex); }
        });
        this.children[2].addEventListener("change", (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            try {
                this.children[0].value = parseDateTime(`${this.children[1].value}T${this.children[2].value}`, "YYYY-MM-DDTHH:mm", this.children[3].value);
                this.children[0].dispatchEvent(new Event("change"));
            } catch (ex) { console.warn(ex); }
        });
        this.children[3].addEventListener("change", (event) => {
            try {
                this.children[0].value = parseDateTime(`${this.children[1].value}T${this.children[2].value}`, "YYYY-MM-DDTHH:mm", this.children[3].value);
                this.children[0].dispatchEvent(new Event("change"));
            } catch (ex) { console.warn(ex); }
        });

        document.querySelectorAll(`[for="${this.id}"]`).forEach(element => element.addEventListener("click", () => this.children[1].focus()));
    }

    get value() {
        return this.children[0].value;
    }

    set value(value) {
        this.children[1].value = formatTimestamp(parseInt(value), "YYYY-MM-DD", this.children[2].value);
        this.children[2].value = formatTimestamp(parseInt(value), "HH:mm", this.children[2].value);
    }

    get timezone() {
        return this.children[3].value;
    }
    set timezone(value) {
        this.children[3].value = value;
    }
}

customElements.define('eb-datetimepicker', EBDateTimePicker);