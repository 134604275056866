import EBDefaultLayout from "../layouts/default";
import EBModalLayout from "../layouts/modal";

const KEYCODE_TAB = 9;
const KEYCODE_ESCAPE = 27;
const KEYCODE_ENTER = 13;

export class Modal extends EventTarget {

    static alert(message, title = "Alert") {
        const component = new EBModalLayout({}, { static: true });
        component.title = title;
        component.content = message;
        component.addControl("OK", { icon: "check-lg" }).addEventListener("click", (e) => component.close());
        return component.open();
    }

    static prompt(message, title = "Input", declineText = "Cancel", acceptText = "Ok") {
        const component = new EBModalLayout({}, { static: true });
        component.title = title;
        component.content = `<div class="form-control form-control--vertical">
                <label for="promptInput">${message}</label>
                <div>
                    <input type="text" id="promptInput">
                </div>
            </div>`;
        component.addControl(acceptText, { icon: "check-lg"}).addEventListener("click", (e) => component.close(component.contentElement.querySelector("input").value));
        component.addControl(declineText, { icon: "x-lg"}).addEventListener("click", (e) => component.close());
        component.contentElement.querySelector("input").addEventListener("keydown", (ev) => { if (ev.key === 'Enter') { component.close(ev.target.value); } });
        return component.open();
    }

    static confirm(message, title = "Confirm", declineText = "No", acceptText = "Yes") {
        const component = new EBModalLayout({}, { static: true });
        component.title = title;
        component.content = message;
        component.addControl(acceptText, { icon: "check-lg"}).addEventListener("click", (e) => component.close(true));
        component.addControl(declineText, { icon: "x-lg" }).addEventListener("click", (e) => component.close(false));
        return component.open();
    }

    static choose(opts) {
        const component = new EBModalLayout({}, { static: true });
        component.title = opts.title || "Choose";
        component.content = `<div class="form-control form-control--vertical">
                <label for="promptInput">${opts.message || "Choose an option"}</label>
                <div>
                    <select>
                        ${(opts.options || []).map(o => `<option value="${o.value || o}">${o.displayText || o.label || o}</option>`)}
                    </select>
                </div>
            </div>`;
        component.addControl(opts.declineText || "Cancel", { icon: "x-lg"}).addEventListener("click", (e) => component.close(undefined));
        component.addControl(opts.acceptText || "Select", { icon: "check-lg"}).addEventListener("click", (e) => component.close(component.contentElement.querySelector("select").value));
        return component.open();
    }
    
    element;

    constructor(...args) {
        super();
        var opts = args.pop() || {};

        this.element = document.body.appendChild(document.createElement("div"));
        this.element.className = "modal";

        if (opts.className) this.element.classList.add(opts.className.split(" "));

        var backdrop = this.element.appendChild(document.createElement("div"));
        backdrop.className = "backdrop";
        if (!!!opts.static) backdrop.addEventListener("click", () => this.dismiss());

        var dialog;
        if (args[0] instanceof EBDefaultLayout) {
            dialog = this.element.appendChild(args[0].element);
            dialog.querySelector("div.header").insertAdjacentHTML("afterEnd", `<div class="close"><button class="btn" role="button" title="close" aria-label="close"><svg class="toggle" data-src="/assets/images/x-lg.svg" aria-hidden="true"></svg></button></div>`);
            dialog.querySelector("div.content").insertAdjacentElement("afterEnd", dialog.querySelector("div.controls"));
        } else {
            dialog = this.element.appendChild(document.createElement("div"));
            dialog.innerHTML = `<div class="header">
                    <h1 class="title">${opts.title || ""}</h1>
                    <div class="subtitle">${opts.subtitle || ""}</div>
                </div>
                <div class="close"><button class="btn" role="button" title="close" aria-label="close"><svg class="toggle" data-src="/assets/images/x-lg.svg" aria-hidden="true"></svg></button></div>
                <div class="content">${opts.content || ""}</div>
                <div class="controls"></div>`;
        }
        dialog.classList.add("dialog");
        dialog.setAttribute("role", "dialog");
        dialog.setAttribute("aria-labelledby", `modal${Date.now()}_label`);
        //dialog.setAttribute("aria-describedby", `modal${Date.now()}_desc`);
        dialog.setAttribute("aria-modal", "true");
        if (opts.class) dialog.classList.add(opts.class.split(" "));

        dialog.querySelector("& > div.header > h1").setAttribute("id", dialog.getAttribute("aria-labelledby"));

        dialog.querySelector("& > div.close > button").addEventListener("click", (e) => this.dismiss());
    }

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    open() {
        this.element.style.display = "block";

        const focusableElements = [...this.element.querySelectorAll(`a[href]:not([tabindex^="-"]), 
            area[href]:not([tabindex^="-"]), 
            input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]), 
            input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked, 
            select:not([disabled]):not([tabindex^="-"]), 
            textarea:not([disabled]):not([tabindex^="-"]), 
            button:not([disabled]):not([tabindex^="-"]), 
            iframe:not([tabindex^="-"]), 
            audio[controls]:not([tabindex^="-"]), 
            video[controls]:not([tabindex^="-"]), 
            [contenteditable]:not([tabindex^="-"]), 
            [tabindex]:not([tabindex^="-"]), 
            div.tox.tox-tinymce > div.tox-editor-container > div.tox-edit-area > iframe`)].filter(element => element.offsetWidth || element.offsetHeight || element.getClientRects().length);

        const firstFocusableEl = focusableElements[0];
        const lastFocusableEl = focusableElements[focusableElements.length - 1];

        this.element.addEventListener('keydown', (e) => {
            const isTabPressed = (e.key === "Tab" || e.keyCode === KEYCODE_TAB);
            const isEscPressed = (e.key === "Escape" || e.keyCode === KEYCODE_ESCAPE);
        
            if (isTabPressed) {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            }
            if (isEscPressed) {
                this.dismiss();
            }
        });

        firstFocusableEl?.focus();

        return new Promise((resolve, reject) => {
            this.resolveFn = resolve;
            this.rejectFn = reject;
        });
    }

    close(value) {
        this.element.style.display = "none";
        this.resolveFn(value);
        this.dispose();
    }

    dismiss() {
        this.element.style.display = "none";
        this.resolveFn(undefined);
        this.dispose();
    }

    dispose() {
        this.element.remove();
    }
    
    show() {
        this.open();
    }
    
    hide() {
        this.element.style.display = "none";
    }

}







export class Modal2 extends EventTarget {

    static alert(message, title = "Alert") {
        return new Promise((resolve, reject) => {
            new Modal({
                title: title,
                size: "sm",
                static: true,
                content: message,
                controls: [
                    { action: "close", label: "OK", ariaLabel: "Ok" }
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.close.click", (event) => { resolve(undefined); event.target.close(); })
            .show();
        });
    }

    static prompt(message, title = "Input", declineText = "Cancel", acceptText = "Ok") {
        return new Promise((resolve, reject) => {
            new Modal({
                title: title,
                size: "sm",
                static: true,
                content: `<div class="control">
                    <label for="promptInput">${message}</label>
                    <div>
                        <input type="text" id="promptInput">
                    </div>
                </div>`,
                controls: [
                    { action: "decline", label: declineText, ariaLabel: declineText },
                    { action: "accept", label: acceptText, ariaLabel: acceptText },
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.decline.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.accept.click", (event) => { resolve(event.target.content.querySelector("input").value); event.target.close(); })
            .show();
        });
    }

    static confirm(message, title = "Confirm", declineText = "No", acceptText = "Yes") {
        return new Promise((resolve, reject) => {
            const instance = new Modal({
                title: title,
                size: "sm",
                static: true,
                content: message,
                controls: [
                    { action: "decline", label: declineText, ariaLabel: declineText },
                    { action: "accept", label: acceptText, ariaLabel: acceptText },
                ]
            });
            instance.on("eb:modal.close.click", (event) => { resolve(false); event.target.close(); });
            instance.on("eb:modal.control.decline.click", (event) => { resolve(false); event.target.close(); });
            instance.on("eb:modal.control.accept.click", (event) => { resolve(true); event.target.close(); });
            instance.show();
            instance.control("accept").focus();
        });
    }

    static choose(opts) {
        return new Promise((resolve, reject) => {
            new Modal({
                title: opts.title || "Choose",
                size: "sm",
                static: true,
                content: `<div class="control">
                    <label for="promptInput">${opts.message || "Choose an option"}</label>
                    <div>
                        <select>
                            ${(opts.options || []).map(o => `<option value="${o.value || o}">${o.displayText || o.label || o}</option>`)}
                        </select>
                    </div>
                </div>`,
                controls: [
                    { action: "decline", label: opts.declineText || "Cancel", ariaLabel: opts.declineText || "Cancel" },
                    { action: "accept", label: opts.acceptText || "Select", ariaLabel: opts.acceptText || "Select" },
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.decline.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.accept.click", (event) => { resolve(event.target.content.querySelector("select").value); event.target.close(); })
            .show();
        });
    }

    #element;

    get element() { return this.#element; }
    get title() { return this.#element.children[1].children[0].children[0].innerHTML; }
    set title(value) { this.#element.children[1].children[0].children[0].innerHTML = value; }
    get content() { return this.#element.querySelector("div.modal__modal__content"); }
    set content(value) { return this.#element.querySelector("div.modal__modal__content").innerHTML = value; }
    get contentElement() { return this.#element.querySelector("div.modal__modal__content"); }
    get contentElementHTML() { return this.#element.querySelector("div.modal__modal__content").innerHTML; }
    set contentElementHTML(value) { return this.#element.querySelector("div.modal__modal__content").innerHTML = value; }
    control(action) { return this.#element.querySelector(`div.modal__modal__footer button[eb-action="${action}"]`); }
    get controls() { return this.#element.querySelectorAll("div.modal__modal__footer button[eb-action]"); }
    
    constructor(opts = {}) {
        super();
        console.log(opts instanceof EBDefaultLayout);
        console.log(typeof opts);
        
        this.#element = document.body.appendChild(document.createElement("div"));
        this.#element.className = "modal";
        if (opts.size) this.#element.classList.add(`modal--${opts.size}`);

        const backdropElement = this.#element.appendChild(document.createElement("div"));
        backdropElement.className = "modal__backdrop";
        if (opts.static != true)
            backdropElement.addEventListener("click", () => {
                if (this.dispatchEvent(new CustomEvent("eb:modal.backdrop.click")))
                    this.dispose();
            });

        const modalElement = this.#element.appendChild(document.createElement("div"));
        modalElement.className = "modal__modal";
        modalElement.setAttribute("aria-label", opts.title || "");
        modalElement.tabIndex = -1;
        
        const headerElement = modalElement.appendChild(document.createElement("div"));
        headerElement.className = "modal__modal__header";
        headerElement.innerHTML = `<div>${opts.title || ""}</div><button type="button" aria-label="Close"><svg data-src="/assets/images/x-lg.svg"></svg></button>`;
        headerElement.children[1].addEventListener("click", () => {
            if (this.dispatchEvent(new CustomEvent("eb:modal.close.click")))
                this.dispose();
        });

        const contentElement = modalElement.appendChild(document.createElement("div"));
        contentElement.className = "modal__modal__content";
        contentElement.innerHTML = opts.content || "";

        const footerElement = modalElement.appendChild(document.createElement("div"));
        footerElement.className = "modal__modal__footer";
        for (let control of (opts?.controls || [])) {
            const buttonElement = footerElement.appendChild(document.createElement("button"));
            buttonElement.className = control.className || "";
            buttonElement.setAttribute("role", "button");
            buttonElement.setAttribute("aria-label", control.ariaLabel);
            buttonElement.setAttribute("eb-action", control.action);
            if (control.disabled != undefined && control.disabled.toString().toLowerCase() == "true")
                buttonElement.setAttribute("disabled", "");
            if (control.visible != undefined && control.visible.toString().toLowerCase() == "false")
                buttonElement.style.setAttribute("display", "none");
            buttonElement.innerHTML = `${control.icon != undefined ? `<svg data-src="${control.icon}"></svg>` : ""}${control.label}`;
            buttonElement.addEventListener("click", (event) => this.dispatchEvent(new CustomEvent(`eb:modal.control.${event.target.getAttribute("eb-action")}.click`)));
        }
    }

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    show() {
        this.#element.style.setProperty("display", "block");
        //document.addEventListener("keydown", (event) => this.#tabTrap(event, this.#element.children[1]));
        this.#element.querySelector(`button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`).focus();
    }

    hide() {
        this.#element.style.removeProperty("display");
        //document.removeEventListener("keydown", (event) => this.#tabTrap(event, this.#element.children[1]));
    }

    close() {
        this.dispose();
    }

    dispose() {
        this.#element.remove();
    }

    #tabTrap(e, element) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed)
            return;

        if (element.contains(document.activeElement) == false) {
            element.querySelector(`button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`).focus();
            e.preventDefault();
        }
    }
}
